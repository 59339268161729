import { notFound } from 'next/navigation';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import { NotesCardQueryData } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

export async function getNote({
  urlMusician,
  urlTitle,
  urlArrangement,
  currency,
}: {
  urlMusician: string | null;
  urlTitle: string | null;
  urlArrangement: string | null;
  currency: string;
}): Promise<NotesCardQueryData> {
  const requestLink = `${
    ENV.API
  }/note/${urlMusician}:${urlTitle}:${urlArrangement}/?${queryString.stringify({
    currency,
    lang: ENV.LANG,
  })}`;

  try {
    const noChach = await getClientNoCache();
    const res = await fetch(requestLink, {
      cache: noChach ? 'no-store' : undefined,
      next: noChach
        ? undefined
        : {
            revalidate: ENV.REVALIDATE_NOTE_S,
            tags: [`/notes/${urlMusician}/${urlTitle}/${urlArrangement}/`, 'ALL_NOTES'],
          },
    });
    const data = (await res.json()) as NotesCardQueryData;

    return data;
  } catch (e) {
    notFound();
    return {} as NotesCardQueryData;
  }
}
